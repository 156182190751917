<template>
    <loader v-if="loading" />
    <div
        v-else
        class="terms-page"
        v-html="terms" />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loader from '@/components/globals/Loader.vue';

export default {
    title: 'Terms of Use',
    components: { Loader },
    data() {
        return {
            terms: {},
            loading: true,
        };
    },
    async created() {
        this.loading = true;
        this.terms = (await this.getAgencyTerms(this.agency.id))?.document;
        this.loading = false;
    },
    computed: {
        ...mapState({
            agency: (state) => state.agency.currentAgency,
        })
    },
    methods: {
        ...mapActions([
            'getAgencyTerms',
        ]),
    }
};
</script>

<style lang="scss">
.terms-page {
    padding: 3rem !important;

    h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
    }

    h2 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        unicode-bidi: isolate;
    }

    ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        unicode-bidi: isolate;
    }


    ::marker {
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0px !important;
        text-align: start !important;
        text-align-last: start !important;
    }

    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
    }

    ul ol {
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

    ol ol {
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
}
</style>
